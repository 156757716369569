var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "googleMap" },
    [
      _c(
        "UcContextUi",
        {
          attrs: { name: "Google Maps", "keep-true": "" },
          on: { "privacy:update": _vm.onPrivacyUpdate },
        },
        [
          _c("div", { ref: "googleMap", staticClass: "googleMap__container" }),
          _vm._v(" "),
          Boolean(this.google) && Boolean(this.map)
            ? [_vm._t("default", null, { google: _vm.google, map: _vm.map })]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }