





























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import GoogleMapsApiLoader from 'google-maps-api-loader/src/google-maps-api-loader';
import GoogleMapsStylesJson from '../../../Configuration/GoogleMapsStyles.json';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';
import UcContextUi from '../../Misc/UcContextUi/UcContextUi.vue';

export default {
    name: 'GoogleMapsLoader',
    components: {UcContextUi},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: null as ContentElementContext,
        apiKey: String,
        config: Object,
        configDefault: {
            Type: Object,
            required: false,
            default: () => (
                {
                    styles: GoogleMapsStylesJson,
                    zoom: 5,
                    minZoom: 2,
                    maxZoom: 15,
                    streetViewControl: false
                }
            )
        }
    },
    data()
    {
        return {
            google: null,
            map: null
        };
    },
    methods: {
        loadMap()
        {
            new GoogleMapsApiLoader({
                apiKey: this.apiKey,
                language: this.context.appContext.translation.languageCode
            })
                .then(this.proxy.promiseProxy())
                .then((google) => {
                    this.google = google;
                    this.initializeMap();
                });
        },
        initializeMap()
        {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, this.mapConfig());
            this.$emit('map__initialized');
        },
        mapConfig(): Object
        {
            let config = {};
            Object.assign(config, this.configDefault, this.config);
            return config;
        },
        onPrivacyUpdate(n)
        {
            if (n) {
                this.loadMap();
            }
        }
    }
};
