




















import {isNull} from '@labor-digital/helferlein/lib/Types/isNull';

export default {
    name: 'GoogleMapMarker',
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        position: {
            type: Object,
            required: true
        },
        icon: {
            type: Object | String,
            required: false
        },
        infoContent: {
            type: String,
            default: ''
        }
    },
    data()
    {
        return {
            markerInstance: null
        };
    },
    methods: {
        recreate(): void
        {
            this.destroy();

            this.markerInstance = new this.google.maps.Marker({
                position: this.position,
                map: this.map,
                icon: this.icon
            });
            this.$emit('marker__created', this.markerInstance);

            this.markerInstance.addListener('click', () => {
                this.$emit('marker__selected', this.position);
            });

            if (this.infoContent !== '') {
                const infoWindow = (new this.google.maps.InfoWindow({
                    content: '<div class="googleMapInfoWindow">' + this.infoContent + '</div>'
                }));
                this.markerInstance.addListener('click', () => {
                    infoWindow.open(this.map, this.markerInstance);
                });
            }
        },
        destroy(): void
        {
            if (!isNull(this.markerInstance)) {
                this.$emit('marker__destroyed', this.markerInstance);
                this.google.maps.event.clearInstanceListeners(this.markerInstance);
                this.markerInstance.setMap(null);
            }
        }
    },
    mounted()
    {
        this.recreate();
    },
    watch: {
        icon(value)
        {
            if (this.markerInstance) {
                this.markerInstance.setIcon(value);
            }
        },
        infoContent()
        {
            this.recreate();
        },
        position(n, o)
        {
            if (this.markerInstance && n !== null && JSON.stringify(n) !== JSON.stringify(o)) {
                this.markerInstance.setPosition(n);
            }
        }
    },
    beforeDestroy(): void
    {
        this.destroy();
    }
};
